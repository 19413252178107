import React from 'react'
import { Link } from 'react-router-dom';

export default function ADVDECStats(props) {
    const getRec = props.getAdvData;
    return (
        <>
            {getRec ? <>
                <table className="table table-bordered">
                    <thead>
                        <tr className="header-row changefont">
                            <th className="indexname" size="3">Date</th>
                            <th className="ltp" size="3">ADV</th>
                            <th className="signal" size="3">DECD</th>
                            <th className="change" size="3">Unchanged</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getRec.map((item, key) => (
                            <tr key={key} size="3">
                                <td className="indexname symbol-text" size="3">{item.date} </td>
                                <td style={{ 'color': item.vcolor }} >{item.columnone} </td>
                                <td style={{ 'color': item.color }} >{item.columntwo}</td>
                                <td style={{ 'color': item.bcolor }} >{item.Unchanged}</td>
                            </tr>
                        ))}
                        <tr>
                            <td colSpan="4" className="text-center">
                                <Link className="btn btn-sm btn-success  " to="/advance-decline-ratio-nse">View</Link>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </>
                : ''}

        </>
    )
}
