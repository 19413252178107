import React from "react";
import { Link } from "react-router-dom";

export default function TradeStats(props) {
  const getRec = props.todayData;
  return (
    <>
      {getRec && getRec[0] ? (
        <table className="table table-bordered">
          <thead>
            <tr className="header-row changefont">
              <th className="indexname" size="3">
                Parameters
              </th>
              <th className="ltp" size="3">
                Pre. Value(Cr.)
              </th>
              <th className="signal" size="3">
                Value(Cr.)
              </th>
              <th className="change" size="3">
                Impact
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th className="indexname symbol-text" size="3">
                FII Index Fut
              </th>
              <td
                size="3"
                className="ltp ltp-text"
                style={{ color: getRec[1]["FIIIndexFutcolor"] }}
              >
                {getRec[1]["FIIIndexFut"]}
              </td>
              <td
                className="ltp ltp-text"
                style={{ color: getRec[1]["FIIIndexFutcolor"] }}
              >
                {getRec[0]["FIIIndexFut"]}
              </td>
              <td
                size="3"
                className="ltp ltp-text"
                style={{ color: getRec[1]["FIIIndexFutclr"] }}
              >
                {getRec[1]["FIIIndexFutImp"]}
              </td>
            </tr>
            <tr className="indexname symbol-text" size="3">
              <th className="indexname symbol-text" size="3">
                FII Stock Opt
              </th>
              <td
                size="3"
                className="ltp ltp-text"
                style={{ color: getRec[1]["FIIIndexOptcolor"] }}
              >
                {getRec[1]["FIIIndexOpt"]}
              </td>
              <td
                size="3"
                className="ltp ltp-text"
                style={{ color: getRec[1]["FIIIndexOptcolor"] }}
              >
                {getRec[0]["FIIIndexOpt"]}
              </td>
              <td
                size="3"
                className="ltp ltp-text"
                style={{ color: getRec[1]["FIIIndexOptclr"] }}
              >
                {getRec[1]["FIIIndexOptImp"]}
              </td>
            </tr>
            <tr className="indexname symbol-text" size="3">
              <th className="indexname symbol-text" size="3">
                FII Stock Fut
              </th>
              <td
                size="3"
                className="ltp ltp-text"
                style={{ color: getRec[1]["FIIStockFutcolor"] }}
              >
                {getRec[1]["FIIStockFut"]}
              </td>
              <td
                size="3"
                className="ltp ltp-text"
                style={{ color: getRec[1]["FIIStockFutcolor"] }}
              >
                {getRec[0]["FIIStockFut"]}
              </td>
              <td
                size="3"
                className="ltp ltp-text"
                style={{ color: getRec[1]["FIIStockFutclr"] }}
              >
                {getRec[1]["FIIStockFutImp"]}
              </td>
            </tr>
            <tr className="indexname symbol-text" size="3">
              <th className="indexname symbol-text" size="3">
                FII Stock Opt
              </th>
              <td
                size="3"
                className="ltp ltp-text"
                style={{ color: getRec[1]["FIItockOptcolor"] }}
              >
                {getRec[1]["FIItockOpt"]}
              </td>
              <td
                size="3"
                className="ltp ltp-text"
                style={{ color: getRec[1]["FIItockOptcolor"] }}
              >
                {getRec[0]["FIItockOpt"]}
              </td>
              <td
                size="3"
                className="ltp ltp-text"
                style={{ color: getRec[1]["FIItockOptclr"] }}
              >
                {getRec[1]["FIItockOptImp"]}
              </td>
            </tr>
            <tr className="indexname symbol-text" size="3">
              <th className="indexname symbol-text" size="3">
                FII/FPI
              </th>
              <td
                size="3"
                className="ltp ltp-text"
                style={{ color: getRec[1]["FIIFPICashcolor"] }}
              >
                {getRec[1]["FIIFPICash"]}
              </td>
              <td
                size="3"
                className="ltp ltp-text"
                style={{ color: getRec[1]["FIIFPICashcolor"] }}
              >
                {getRec[0]["FIIFPICash"]}
              </td>
              <td
                size="3"
                className="ltp ltp-text"
                style={{ color: getRec[1]["FIIFPICashclr"] }}
              >
                {getRec[1]["FIIFPICashImp"]}
              </td>
            </tr>
            <tr className="indexname symbol-text" size="3">
              <td colSpan="4" className="text-center">
                <Link
                  className="btn btn-sm btn-success  "
                  to="/fii-dii-data-nse"
                >
                  View
                </Link>
              </td>
            </tr>
          </tbody>
        </table>
      ) : (
        ""
      )}
    </>
  );
}
