import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function TodatGainerLoser(props) {
  const todayGainer = props?.todayGainer;
  const todayLoser = props?.todayLoser;
  const [result, setResult] = useState(todayGainer);
  const [status, setStatus] = useState(1);

  useEffect(() => {
    setResult(todayGainer);
  }, [todayGainer]);
  const handleClick = (val) => {
    val === 1 ? setResult(todayGainer) : setResult(todayLoser);
    setStatus(val);
  };

  return (
    <div className="row">
      <div className="col-6">
        <h3>
          <span className="highlighttext">
            <i className="fa fa-sort"></i> Today's Gainer / Loser
          </span>
        </h3>
      </div>
      <div className="col-6  ">
        <div className="customNav">
          <div
            onClick={(e) => handleClick(1)}
            className={status === 1 ? "active childData" : "childData"}
          >
            Gainer
          </div>
          <div
            onClick={(e) => handleClick(0)}
            className={status === 0 ? "active childData" : "childData"}
          >
            Loser
          </div>
        </div>
      </div>
      <div className="col-12 mt-2">
        <table className="table table-bordered">
          <thead>
            <tr className="header-row changefont">
              <th className="indexname" size="3">
                Symbol
              </th>
              <th className="ltp text-center" size="3">
                LTP
              </th>
              <th className="signal text-center" size="3">
                Change
              </th>
              <th className="change text-center" size="3">
                %CHG
              </th>
            </tr>
          </thead>
          <tbody>
            {result &&
              result.map((item, key) => (
                <tr key={key} size="3">
                  <td className="indexname symbol-text" size="3">
                    <Link to={`/stocks/${item.symbol}`}>{item.symbol}</Link>
                  </td>
                  <td className="text-center">{item.ltp} </td>
                  <td className="text-center">{item.chg}</td>
                  <td
                    className="text-center"
                    style={{
                      color:
                        item.cp >= 0 ? "rgb(76, 175, 80)" : "rgb(244, 67, 54)",
                    }}
                  >
                    {item.cp}
                  </td>
                </tr>
              ))}
            <tr>
              <td colSpan="4" className="text-center">
                {status === 1 ? 
                <Link
                  className="btn btn-sm btn-success  "
                  to="/top-gainers-nse"
                >
                  View
                </Link>
                : 
                <Link
                  className="btn btn-sm btn-success  "
                  to="/top-losers-nse"
                >
                  View
                </Link>
                }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
