import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import RestService from "./../../../Restservice";
import Loader from "./../../../component/Loader";
import OptionChart from "./../../../component/OptionChart";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className="highlighttext">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function OptionDialogBox(props) {
  let mobile = "";
  let web_token = "";
  const userdata = localStorage.getItem("userdata")
    ? JSON.parse(localStorage.getItem("userdata"))
    : "";
  mobile = userdata.mobile ? userdata.mobile : "";
  web_token = userdata.web_token ? userdata.web_token : "";
  const [loading, setLoading] = useState(false);
  const [getDetails, setGtDetails] = useState(false);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lgx"));
  const heading = props.heading;
  const columnKey = props.columnKey;
  const keyname = props.keyname;
  const order = props.order;
  const [viewMode, setViewMode] = useState("table");

  const handleClickOpen = () => {
    setLoading(true);
    RestService.getFutureOptionData(
      function (res) {
        setLoading(false);
        const response = JSON.parse(res);
        setGtDetails(response.data.results);
        setOpen(true);
      },
      mobile,
      web_token,
      keyname,
      order
    );
  };
  const handleClose = () => {
    setOpen(false);
  };
  const updateViewMode = (mode) => {
    setViewMode(mode);
  };
  return (
    <div>
      {loading ? <Loader /> : ""}
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        View More
      </Button>
      <Dialog
        fullScreen={fullScreen}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {heading}
        </DialogTitle>
        <DialogContent dividers>
          <div className="row">
            <div className="col-sm-12 mt-2 mb-4">
              <button
                onClick={() => updateViewMode("table")}
                className="btn btn-success"
              >
                Table View
              </button>
              <button
                onClick={() => updateViewMode("graph")}
                className="btn btn-primary ml-2"
              >
                Graph View
              </button>
            </div>
          </div>
          {viewMode === "graph" && (
            <div className="row">
              <div
                className="col-sm-12 "
                style={{ width: "500px", minHeight: "300px" }}
              >
                <OptionChart
                  getDetails={getDetails}
                  label="symbol"
                  columnNameOne="close"
                  columnNameTwo="columnKey"
                  columnName={columnKey}
                  heading={heading}
                />
              </div>
            </div>
          )}
          {viewMode === "table" && (
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Symbol</th>
                  <th>LTP </th>
                  <th>{columnKey}</th>
                  <th>Exp Date</th>
                </tr>
              </thead>
              <tbody>
                {getDetails &&
                  getDetails.map((item, key) => (
                    <tr key={key}>
                      <td>{item.symbol}</td>
                      <td>{parseFloat(item.close).toFixed(2)}</td>
                      <td>{item.columnKey}</td>
                      <td>{item.expiry_date}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
