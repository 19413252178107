import React, { useEffect, useState } from "react";
import GlobalHelmet from "./../../GlobalHelmet";
import Search from "./Search";
import RestService from "./../../Restservice";
import { Link } from "react-router-dom"; 
import Common from "./../../common.json";
import Loader from "./../../component/Loader";
import TradeStats from "./TradeStats";
import FIIDIIStats from "./FIIDIIStats";
import ADVDECStats from "./ADVDECStats";
import TodatGainerLoser from "./TodatGainerLoser";
import HighestHighLow from "./HighestHighLow";
import FutureOptionData from "./FutureOptionData";
import { getUserData } from "../../util/util";

function Index() {
  const [loading, setLoading] = useState(false);
  const [globaldata, setglobaldata] = useState({}); 
  const mobile = getUserData()?.mobile ;
  const web_token = getUserData()?.web_token;

  useEffect(() => {
    setLoading(true);
    RestService.gethomeList(
      function (res) {
        setLoading(false);
        const response = JSON.parse(res);
        setglobaldata(response.data.response);
        if (response.data.response?.userStatus === 0 && getUserData()) {
          localStorage.clear();
        }
        console.log('globaldata',globaldata)
      },
      mobile,
      web_token
    );
  }, [mobile, web_token]);

   

  return (
    <>
      {loading ? <Loader /> : ""}
      {!globaldata ? (
        ""
      ) : (
        <>
          <GlobalHelmet {...Common?.home} />
          <div className="bodycontainer">
            <Search globaldata={globaldata} />
            <div className="addhome">
              <div className="custom-auto-space pt-0">
              <h1 className="highlighttext">Market Activity </h1>
                <div className="row"> 
                  <div className="col-lg-4 p-1">
                    <h3>
                      <span className="highlighttext">Today`S Activity</span>
                    </h3>
                    <TradeStats
                      todayData={
                        globaldata.todayData ? globaldata.todayData : []
                      }
                    />
                  </div>
                  <div className="col-lg-4 p-1">
                    <h3>
                      <span className="highlighttext">FII/DII Cash</span>
                    </h3>
                    <FIIDIIStats
                      fiidii={globaldata.fiidii ? globaldata.fiidii : []}
                    />
                  </div>
                  <div className="col-lg-4 p-1">
                    <h3>
                      <span className="highlighttext">ADV/DEC </span>
                    </h3>
                    <ADVDECStats
                      getAdvData={
                        globaldata.getAdvData ? globaldata.getAdvData : []
                      }
                    />
                  </div>
                  <div className="col-md-12 text-center">
                    <Link
                      className="home-btn-outline  "
                      to="/nse-option-chain-data/"
                    >
                      View All
                    </Link>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <h2 className="sectionheading">Market Movers</h2>
                    <p className="sectiondesc">
                      Get today's market updates & find here the list of
                      Gainers, Losers, 52 Week High Stocks, 52 week Low Stocks
                      and more.
                    </p>
                  </div>
                  <div className="col-sm-6">
                    {globaldata && (
                      <HighestHighLow
                        high52Data={
                          globaldata.high52Data ? globaldata.high52Data : []
                        }
                        low52Data={
                          globaldata.low52Data ? globaldata.low52Data : []
                        }
                      />
                    )}
                  </div>
                  <div className="col-sm-6">
                    {globaldata && (
                      <TodatGainerLoser
                        todayGainer={
                          globaldata.todayGainer ? globaldata.todayGainer : []
                        }
                        todayLoser={
                          globaldata.todayLoser ? globaldata.todayLoser : []
                        }
                      />
                    )}
                  </div>
                </div>
                {globaldata?.optionData &&  <FutureOptionData globaldata={globaldata?.optionData} /> }
              </div>   
            </div>
          </div> 
        </>
      )}
    </>
  );
}

export default Index;
