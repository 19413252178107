import React from "react";
import { Bar } from "react-chartjs-2";

export default function OptionChat(props) {
  const columnNameOne = props?.columnNameOne ? props.columnNameOne : "symbol";
  const columnNameTwo = props?.columnNameTwo
    ? props.columnNameTwo
    : "contracts";
  const heading = props?.heading;
  const label = props?.label;
  const labelText = props?.labelText ? props.labelText : "LTP";
  const columnName = props?.columnName;
  const getDetails = props?.getDetails;
  const arrayColumn = (arr, n) => arr?.map((x) => parseFloat(x[n]));
  const arrayStringColumn = (arr, n) => arr?.map((x) => x[n]);
  const labels = arrayStringColumn(getDetails, label);

  const options = {
    indexAxis: "x",
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "left",
      },
      title: {
        align: "left",
        display: true,
        fullSize: true,
        font: { weight: "bold" },

        text: heading,
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: labelText,
        data: arrayColumn(getDetails, columnNameOne),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: columnName,
        data: arrayColumn(getDetails, columnNameTwo),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };
  return <Bar options={options} data={data} />;
}
