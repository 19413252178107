import React from "react";
import OptionDialogBox from "./OptionDialogBox";

export default function OptionChainTable(props) {
  const results = props.results;
  const heading = props.heading;
  const columnKey = props.columnName;
  const keyname = props.columnKey;
  const optiontype = props.optiontype;
  const symbol = props.symbol;
  const order = props.order;
  return (
    <>
      <div className="table-comodity">
        {results && results.length > 0 ? (
          <>
            <h3 className="highlighttext">{heading}</h3>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Symbol</th>
                  <th>LTP </th>
                  <th>{columnKey}</th>
                  <th>Exp Date</th>
                </tr>
              </thead>
              <tbody>
                {results.map((item, key) => (
                  <tr key={key}>
                    <td>{item.symbol}</td>
                    <td>{parseFloat(item.close).toFixed(2)}</td>
                    <td>{item.columnKey}</td>
                    <td>{item.expiry_date}</td>
                  </tr>
                ))}
                <tr>
                  <td colSpan="4" className="text-center">
                    <OptionDialogBox
                      symbol={symbol}
                      columnKey={columnKey}
                      keyname={keyname}
                      heading={heading}
                      optiontype={optiontype}
                      order={order}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
