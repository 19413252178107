import React from "react";
import axios from "axios";
import "./search.css";
import { Link } from "react-router-dom";

class Search extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      query: "",
      results: {},
      loading: false,
      message: "",
      totalResults: 0,
      totalPages: 0,
      currentPageNo: 0,
    };

    this.cancel = "";
  }

  /**
   * Get the Total Pages count.
   *
   * @param total
   * @param denominator Count of results per page
   * @return {number}
   */
  getPageCount = (total, denominator) => {
    const divisible = 0 === total % denominator;
    const valueToBeAdded = divisible ? 0 : 1;
    return Math.floor(total / denominator) + valueToBeAdded;
  };

  /**
   * Fetch the search results and update the state with the result.
   * Also cancels the previous query before making the new one.
   *
   * @param {int} updatedPageNo Updated Page No.
   * @param {String} query Search Query.
   *
   */
  fetchSearchResults = (updatedPageNo = "", query) => {
    const pageNumber = updatedPageNo ? `&page=${updatedPageNo}` : "";
    const Baseurl = process.env.REACT_APP_base_url;
    const searchUrl = `${Baseurl}search/searchdata?q=${query}${pageNumber}`;
    //const searchUrl = `https://pixabay.com/api/?key=20375628-f1deb54182acd62662c800a2e&q=${query}${pageNumber}`;

    if (this.cancel) {
      this.cancel.cancel();
    }

    this.cancel = axios.CancelToken.source();

    axios
      .get(searchUrl, {
        cancelToken: this.cancel.token,
      })
      .then((res) => {
        const total = res.data.total;
        const totalPagesCount = this.getPageCount(total, 20);
        const resultNotFoundMsg = !res.data.response.length
          ? "There are no more search results. Please try a new search"
          : "";
        this.setState({
          results: res.data.response,
          message: resultNotFoundMsg,
          totalResults: total,
          totalPages: totalPagesCount,
          currentPageNo: updatedPageNo,
          loading: false,
        });
      })
      .catch((error) => {
        if (axios.isCancel(error) || error) {
          this.setState({
            loading: false,
            message: "Failed to fetch the data. Please check network",
          });
        }
      });
  };

  handleOnInputChange = (event) => {
    const query = event.target.value;
    if (!query) {
      this.setState({
        query,
        results: {},
        message: "",
        totalPages: 0,
        totalResults: 0,
      });
    } else {
      this.setState({ query, loading: true, message: "" }, () => {
        this.fetchSearchResults(1, query);
      });
    }
  };

  /**
   * Fetch results according to the prev or next page requests.
   *
   * @param {String} type 'prev' or 'next'
   */
  handlePageClick = (type) => {
    //event.preventDefault();
    const updatePageNo =
      "prev" === type
        ? this.state.currentPageNo - 1
        : this.state.currentPageNo + 1;

    if (!this.state.loading) {
      this.setState({ loading: true, message: "" }, () => {
        this.fetchSearchResults(updatePageNo, this.state.query);
      });
    }
  };

  renderSearchResults = () => {
    const { results } = this.state;

    if (Object.keys(results).length && results.length) {
      return (
        <div>
          {results.map((result) => {
            return (
              <>
                <div>
                  <Link to={"stocks/" + result.symbol}>
                    <strong>{result.name}</strong>
                  </Link>
                  <span className="badge badge-dark custom-batch ">
                    {result.c}{" "}
                    <span className={result.classname}> ({result.cp}%) </span>
                  </span>
                  <p className="customrec">
                    <span>LTP : {result.l}</span> <span>O : {result.op}</span>{" "}
                    <span>H : {result.hi}</span> <span>L : {result.lo}</span>{" "}
                  </p>
                </div>
              </>
            );
          })}
        </div>
      );
    }
  };

  render() {
    return (
      <>
        <div className="search-section">
          {this.props?.globaldata?.isWebinar&&  <div className="webinar-container">
                <Link to="/free-webinar" area-lable="Money tree Robo Logo">
                  <img
                    className="blink-img"
                    src={require("../../images/freeWebinar.png").default}
                    alt="Moneytree"
                    prop=""
                  /> 
                </Link>
              </div>}
          <img
            className="demo-bg"
            src={require("../../images/header_image1.png").default}
            alt="Moneytree Header"
          />

          <div className="searchcomp">
            <div className="text-center">
              <h1>Money Tree Robo Stock Screener</h1>
              <h2>Equity Assistant</h2>
              <p>
                Stock Screener that helps to choose right stocks at right time.
              </p>
            </div>
            <div className="clearfix"></div>

            <div
              className="form-group mt-3 aos-init aos-animate autocomplete"
              data-aos="fade-up"
              data-aos-duration="300"
              data-aos-delay="150"
            >
              <input
                name="txtSearchComp"
                autoComplete="off"
                id="txtSearchComp"
                className="form-control searchbox"
                onChange={this.handleOnInputChange}
                autoFocus=""
                type="search"
                placeholder="Type a Company Name to Search"
              />
              <div className="autocomplete-items">
                {this.renderSearchResults()}
              </div>
            </div>
            <div className="clearfix"></div>

            <div className="frequents">
              <span className="whats_trending" data-aos="fade-right">
                What's Trending:
              </span>

              <Link
                title="Reliance Industries"
                to="/stocks/reliance-share-price"
                className="btn btn-sm  badge-pill badge-primary"
              >
                RELIANCE
              </Link>

              <Link
                title="ITC"
                to="/stocks/itc-share-price"
                className="btn btn-sm   badge-pill badge-primary"
              >
                ITC
              </Link>

              <Link
                title="Yes Bank"
                to="/stocks/yesbank-share-price"
                className="btn btn-sm  badge-pill badge-primary"
              >
                YESBANK
              </Link>

              <Link
                title="IRCTC"
                to="/stocks/irctc-share-price"
                className="btn btn-sm  badge-pill badge-primary"
              >
                IRCTC
              </Link>

              <Link
                title="Bajaj Finance"
                to="/stocks/bajfinance-share-price"
                className="btn btn-sm  badge-pill badge-primary"
              >
                BAJFINANCE
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Search;
