import React from 'react'
import { Link } from 'react-router-dom';

export default function FIIDIIStats(props) {
    const getRec = props.fiidii;
    return (
        <>
            {getRec ? <>
                <table className="table table-bordered">
                    <thead>
                        <tr className="header-row changefont">
                            <th className="indexname" size="3">Date</th>
                            <th className="ltp" size="3">Vo(Cr.)</th>
                            <th className="signal" size="3">Nifty</th>
                            <th className="change" size="3">BNifty</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getRec.map((item, key) => (
                            <tr key={key} size="3">
                                <td className="indexname symbol-text" size="3">{item.sdate} </td>
                                <td style={{ 'color': item.vcolor }} >{item.columnValues} </td>
                                <td style={{ 'color': item.color }} >{item.chg}</td>
                                <td style={{ 'color': item.bcolor }} >{item.bchg}</td>
                            </tr>
                        ))}
                        <tr>
                            <td colSpan="4" className="text-center">
                                <Link className="btn btn-sm btn-success  " to="/fii-dii-activities">View</Link>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </>
                : ''}
        </>
    )
}
